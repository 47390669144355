import "lazysizes";
import "./bundle/lightbox.js";

// import normalizeVH from "./bundle/rcobiella-helpers.js";
// import debounce from "./bundle/rcobiella-helpers.js";
import {
	debounce,
	normalizeVH,
	smoothScroll,
} from "./bundle/rcobiella-helpers.js";

import Cookies from "js-cookie";
import Headroom from "headroom.js";

normalizeVH();
document.addEventListener("resize", debounce(normalizeVH(), 10));

/* -------------------------------------------------------------------------- */
/*                              LazySizes config                              */
/* -------------------------------------------------------------------------- */

// window.lazySizesConfig = {
// 	loadMode: 3,
// 	expand: 1000,
// };

/* -------------------------------------------------------------------------- */
/*                                a11y outline                                */
/* -------------------------------------------------------------------------- */
// https://medium.com/better-programming/a11y-never-remove-the-outlines-ee4efc7a9968
(function (document, window) {
	var styleText =
		"::-moz-focus-inner{border:0 !important;}:focus{outline: none !important;box-shadow: none !important;";
	var unfocus_style = document.createElement("style");

	window.unfocus = function () {
		document.getElementsByTagName("head")[0].appendChild(unfocus_style);

		document.addEventListener("mousedown", function () {
			unfocus_style.innerHTML = styleText + "}";
		});
		document.addEventListener("keydown", function () {
			unfocus_style.innerHTML = "";
		});
	};

	unfocus.style = function (style) {
		styleText += style;
	};

	unfocus();
})(document, window);

jQuery(function ($) {
	// Rename element (change HTML tag)
	// $('div#toChange').replaceTag('span')
	// https://stackoverflow.com/questions/13389751/change-tag-using-javascript
	$.fn.replaceTag = function (newTag) {
		var originalElement = this[0],
			originalTag = originalElement.tagName,
			startRX = new RegExp("^<" + originalTag, "i"),
			endRX = new RegExp(originalTag + ">$", "i"),
			startSubst = "<" + newTag,
			endSubst = newTag + ">",
			newHTML = originalElement.outerHTML
				.replace(startRX, startSubst)
				.replace(endRX, endSubst);
		this.replaceWith(newHTML);
	};

	/* ---------------------------------------------------------------- */
	/*                    EXTERNAL LINKS IN NEW TAB                     */
	/* ---------------------------------------------------------------- */
	$(document.links)
		.filter(function () {
			return this.hostname != window.location.hostname;
		})
		.attr("target", "_blank");

	/* -------------------------------------------------------------------------- */
	/*                     SKIP LINKS - set main content start                    */
	/* -------------------------------------------------------------------------- */
	// Sólo si el primer hijo de main es slider-main, añadimos un anchor nuevo para el skip link.
	const $skip_main = $("#skip-main");
	let $main_first = $("#main > *").first();
	let $main_2nd = $("#main > *").first().next();
	// Check if first is carousel
	if ($main_first.hasClass("block-slider-main") && $main_2nd.length) {
		// Hay slider, main es el segundo
		$main_2nd.attr("id", "main-start");
		$skip_main.attr("href", "#main-start");
	}

	/* -------------------------------------------------------------------------- */
	/*                              isMobile & TOUCH                              */
	/* -------------------------------------------------------------------------- */

	const TOUCH = "ontouchstart" in window;
	let isMobile = window.innerWidth <= 1024;
	const checkMobile = () => {
		isMobile = window.innerWidth <= 1024;
	};
	window.addEventListener("resize", debounce(checkMobile, 10));

	/* ------------------------------ Smooth Scroll ----------------------------- */
	smoothScroll();

	/* -------------------------------------------------------------------------- */
	/*                                  Headroom                                  */
	/* -------------------------------------------------------------------------- */

	const headroomOptions = {
		offset: 200,
		// scroll tolerance in px before state changes
		// tolerance: 0,
		// // or you can specify tolerance individually for up/down scroll
		// tolerance: {
		// 	up: 5,
		// 	down: 0
		// },
		classes: {
			// when element is initialised
			initial: "headroom",
			// when scrolling up
			pinned: "header-is-pinned",
			// when scrolling down
			unpinned: "header-is-unpinned",
			// when above offset
			top: "header-is-top",
			// when below offset
			notTop: "header-is-not-top",
			// when at bottom of scoll area
			bottom: "header-is-bottom",
			// when not at bottom of scroll area
			notBottom: "header-is-not-bottom",
			// when frozen method has been called
			frozen: "header-is-frozen",
		},
	};
	const headroom = new Headroom(document.body, headroomOptions);
	headroom.init();

	/* -------------------------------------------------------------------------- */
	/*                                 Menu toggle                                */
	/* -------------------------------------------------------------------------- */

	// Fcous check
	// $("body").on("focus", "*", function () {
	// 	console.log("focus", $(this).get(0).classList);
	// });
	const $menuOverlay = $(".menu-overlay"),
		$menuIcon = $("#menu-toggle"),
		$menuAria = $("#nav-menu-main"),
		$menuFooter = $("#nav-footer"),
		$main = $("#main");

	// Modify empty # links (menu headers) for ARIA
	$menuAria.find('a[href="#"]').each(function () {
		let $dis = $(this),
			html = $dis.html();
		if (html) {
			$dis.attr("aria-label", html)
				.removeAttr("href")
				.attr("tabindex", "0")
				.replaceTag("h2");
		}
	});
	$menuFooter.find('a[href="#"]').each(function () {
		let $dis = $(this),
			html = $dis.html();
		if (html) {
			$dis.attr("aria-label", html)
				.removeAttr("href")
				.attr("tabindex", "0")
				.replaceTag("h2");
		}
	});
	const $focusableInNav = $(
		'#nav-menu-main [href], #nav-menu-main [tabindex]:not([tabindex="-1"])'
	);
	let $firstFocusableElement = $focusableInNav.first(),
		$lastFocusableElement = $focusableInNav.last();

	function navAddListeners() {
		$menuIcon.click(toggleNav);
		//$closeBtn.click(closeNav);
		$menuAria.on("keyup", closeNav);
		$firstFocusableElement.on("keydown", navFocusToBottom);
		$lastFocusableElement.on("keydown", navFocusToTop);
	}
	navAddListeners();
	function toggleNav(e) {
		$("html").toggleClass("menu-is-active");
		// If it's opening
		if ($("html").hasClass("menu-is-active")) {
			openNav(e);
		} else {
			closeNav(e);
		}
	}

	function openNav() {
		$menuIcon.addClass("is-active");
		$menuOverlay.fadeIn(500);
		$menuIcon.attr("aria-expanded", "true");
		$menuAria.attr("aria-hidden", "false");
		$("html").addClass("menu-is-active");
		setTimeout(function () {
			$firstFocusableElement.focus();
		}, 1);
	}

	function closeNav(e) {
		if (e.type === "keyup" && e.key !== "Escape") {
			return;
		}
		$menuIcon.removeClass("is-active");
		$menuOverlay.fadeOut(500);
		$menuIcon.attr("aria-expanded", "false");
		$menuAria.attr("aria-hidden", "true");
		$("html").removeClass("menu-is-active");
		setTimeout(function () {
			$menuIcon.focus();
		}, 1);
	}
	function navFocusToTop(e) {
		if (e.key === "Tab" && !e.shiftKey) {
			e.preventDefault();
			$menuIcon.focus();
		}
	}

	function navFocusToBottom(e) {
		if (e.key === "Tab" && e.shiftKey) {
			e.preventDefault();
			$lastFocusableElement.focus();
		}
	}

	/* -------------------------------------------------------------------------- */
	/*                                  Dropdown                                  */
	/* -------------------------------------------------------------------------- */
	$(".dropdown-trigger").on("click", function () {
		let $dis = $(this),
			$parent = $dis.parent(".dropdown"),
			$list = $parent.find(".dropdown-list");

		$parent.toggleClass("is-active");
		// if ($parent.hasClass("is-active")) {
		// 	$list.slideDown();
		// } else {
		// 	$list.slideUp();
		// }
	});

	/* -------------------------------------------------------------------------- */
	/*                       Elenco - Image hover en archive                      */
	/* -------------------------------------------------------------------------- */
	const $elencoHoverName = $(".elenco-header-name"),
		$elencoHoverImage = $(".elenco-header-image img");

	if (!TOUCH) {
		$(".grid-item-elenco").on("mouseenter mouseleave", function (e) {
			let $dis = $(this),
				name = $dis.attr("title"),
				img = $dis.attr("data-image");

			// Hover in
			if (e.type == "mouseenter") {
				if (name && img) {
					$elencoHoverName.html(name);
					$elencoHoverImage.attr("src", img);
				}
			} else {
				$elencoHoverName.html("");
				$elencoHoverImage.removeAttr("src");
			}
		});
	}

	/* -------------------------------------------------------------------------- */
	/*                                 Share links                                */
	/* -------------------------------------------------------------------------- */
	$(".share-trigger").on("click", shareTrigger);
	function shareTrigger() {
		const $dis = $(this);
		const $par = $dis.parents(".share-container");
		const $cont = $dis.next(".share-links");
		if ($par.hasClass("is-active")) {
			$par.removeClass("is-active");
			//$cont.animate({ width: "toggle" });
		} else {
			$par.addClass("is-active");
			//$cont.animate({ width: "toggle" });
		}
	}

	/* -------------------------------------------------------------------------- */
	/*                                   Search                                   */
	/* -------------------------------------------------------------------------- */
	const $searchBox = $("#search-box");
	// $("li.search-trigger")
	// 	.attr("id", "search-toggle")
	// 	.attr("tabindex", "0")
	// 	.attr("aria-expanded", "false")
	// 	.attr("aria-controls", "search-box")
	// 	.find('[href="#"]')
	// 	.attr("tabindex", "-1");

	$("body").on("click", ".search-close", closeSearch);
	$("body").on("click", "#search-toggle", openSearch);

	$("body").on("keyup", ".search-trigger", function (e) {
		if (e.key !== "Enter" && e.key !== "Tab") {
			e.preventDefault();
			return;
		}
		if (e.key === "Enter") {
			openSearch();
		}
	});

	function openSearch(e) {
		$("html").addClass("search-is-active");
		$searchBox.attr("aria-hidden", "false");
		$("#search-form").attr("aria-hidden", "false").attr("tabindex", "0");
		$searchBox.find('[tabindex="-1"]').attr("tabindex", "0");
		setTimeout(function () {
			$searchBox.find("#search-text").focus();
		}, 1);
	}
	function closeSearch(e) {
		$("html").removeClass("search-is-active");
		$("#search-form").attr("aria-hidden", "true").attr("tabindex", "-1");
		$searchBox.attr("aria-hidden", "true");
		$searchBox.find('[tabindex="0"]').attr("tabindex", "-1");
		setTimeout(function () {
			$("#search-toggle").focus();
		}, 1);
	}
	// Close search on ESC key
	$searchBox.on("keyup", function (evt) {
		if (evt.key !== "Escape") {
			return;
		}
		if (evt.key === "Escape") {
			closeSearch();
		}
	});

	/* -------------------------------------------------------------------------- */
	/*                              Lightbox gallery                              */
	/* -------------------------------------------------------------------------- */
	if ($(".lightbox").length) {
		$(".lightbox").each(function () {
			const $dis = $(this);
			const lightbox = $(".lightbox a.lightbox-item").simpleLightbox({
				animationSlide: false,
				className: "lightbox-container",
				navText: ["", ""],
				history: false,
				spinner: false,
			});
		});
	}

	/* -------------------------------------------------------------------------- */
	/*                              Google Translate                              */
	/* -------------------------------------------------------------------------- */
	function triggerHtmlEvent(element, eventName) {
		var event;
		if (document.createEvent) {
			event = document.createEvent("HTMLEvents");
			event.initEvent(eventName, true, true);
			element.dispatchEvent(event);
		} else {
			event = document.createEventObject();
			event.eventType = eventName;
			element.fireEvent("on" + event.eventType, event);
		}
	}

	$(".glang-select").click(function () {
		var theLang = jQuery(this).attr("data-lang");
		jQuery(".goog-te-combo").val(theLang);
		//alert(jQuery(this).attr('href'));
		window.location = jQuery(this).attr("href");
		location.reload();
	});

	/* -------------------------------------------------------------------------- */
	/*                                A11y controls                               */
	/* -------------------------------------------------------------------------- */

	/* -------------------------------- FONT SIZE ------------------------------- */

	// Check for cookie, then apply attr to html
	let fontSizeCookie = Cookies.get("fontsize");
	let darkModeCookie = Cookies.get("darkmode");
	if (fontSizeCookie && fontSizeCookie <= 5 && fontSizeCookie >= 1) {
		$("html").attr("fontsize", fontSizeCookie);
	}

	if (darkModeCookie == "1") {
		$("body").addClass("is-dark-mode");
	}

	const $a11ySizeUp = $(".a11y-trigger.a11y-size-up"),
		$a11ySizeDown = $(".a11y-trigger.a11y-size-down"),
		$a11yDarkMode = $(".a11y-trigger.a11y-dark"),
		$a11yContrast = $(".a11y-trigger.a11y-contrast");

	$a11ySizeUp.on("click", function () {
		a11yFontCheck(true);
	});
	$a11ySizeDown.on("click", function () {
		a11yFontCheck(false);
	});

	// a11yFontCheck(true): size up — false size down
	function a11yFontCheck(up) {
		const currentSize = $("html").attr("fontsize"),
			minSize = 1,
			maxSize = 5;

		// Change size up
		if (up && currentSize < maxSize) {
			let targetSize = +currentSize + 1;
			$("html").attr("fontsize", targetSize);

			// Set cookie
			Cookies.set("fontsize", targetSize);
		} else if (!up && currentSize > minSize) {
			let targetSize = +currentSize - 1;
			$("html").attr("fontsize", targetSize);
			Cookies.set("fontsize", targetSize);
		}
	}

	/* -------------------------------- DARK MODE ------------------------------- */
	$(".a11y-trigger.a11y-dark").on("click", function () {
		$("body").toggleClass("is-dark-mode");
		if ($("body").hasClass("is-dark-mode")) {
			Cookies.set("darkmode", "1");
		} else {
			Cookies.set("darkmode", "0");
		}
	});

	/* -------------------------------------------------------------------------- */
	/*                          Triggers: add aria-label                          */
	/* -------------------------------------------------------------------------- */

	$(".search-trigger").each(function () {
		let $dis = $(this),
			$posLinks = $dis.find("> a");
		$dis.attr("aria-label", "Buscar").attr("aria-role", "button");
		if ($posLinks.length) {
			$posLinks.attr("aria-hidden", "true");
		}
	});
	/* -------------------------------------------------------------------------- */
	/*                                Loader + AOS                                */
	/* -------------------------------------------------------------------------- */

	let ignore_unload = false;

	// Prevent if link has mailto
	$("a[href^=mailto], a[href^=tel]").on("click", function () {
		ignore_unload = true;
	});

	$(window).on("beforeunload", function (e) {
		if (!ignore_unload) {
			$("#loader").removeClass("is-loaded");
		}
		ignore_unload = false;
	});

	let hash;
	$(window).on("load", function () {
		setTimeout(function () {
			hash = location.hash;
			if (hash) {
				hash = hash.replace("#", "");
				let scrollToNode = document.getElementById(hash);
				if (scrollToNode) {
					window.scrollTo({
						top: scrollToNode.offsetTop,
						behavior: "auto",
					});
				}
			}

			// Remove preload-transitions class from body to re-enable transitions
			$("body").removeClass("preload-transitions");
			$("#loader").addClass("is-loaded");
		}, 0);
	});
});
